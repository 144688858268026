<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Les Besoins</h3>
            <div class="mid">
            </div>
        </div>


        <div class="row">
            <div class="col-lg-3" v-for="besoin in  besoins " v-bind:key="besoin.id">
                <div class="card">
                    <h4 class="titleblue titlecontainershop titlebesoins"><b>{{ besoin.titre }}</b></h4>
                    <img :src="'https://admin.asso-refuge.fr/images/' + besoin.img"
                        :alt="'photo de la boutique de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :title="besoin.titre"
                        style="height:300px;display: block;margin-left: auto;margin-right: auto; width: 100%;" />
                    <div class="container" style="margin-top: 20px;">
                        <p v-html="besoin.content"></p>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style src="../assets/css/besoins.css"></style>
<script>
export default {
    data() {
        return {
            besoins: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getBesoins/${idSite}`)
            .then(response => response.json())
            .then(data => {
                const formattedBesoins = data.map(besoin => ({
                    ...besoin,
                    content: besoin.content.replace(/\r\n/g, '<br>'),
                }));
                console.log(formattedBesoins);
                this.besoins = formattedBesoins;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
