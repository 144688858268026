<template>
    <div class="homeContainerRefuge">

        <div class="underLogoPhrase">
            <h2 class="font-weight-bold mb-4 centers">La Boutique</h2>
            <p class="littlesize">
                Notre boutique est une source de revenus primordiale pour l association.
                <br>
                Les produits ci-dessous sont disponibles au refuge.
            </p>
        </div>




        <div class="row">
            <div class="col-lg-3" v-for="shop in  shops " v-bind:key="shop.id">
                <div class="card">
                    <img :src="'https://admin.asso-refuge.fr/images/' + shop.img"
                        :alt="'photo de la boutique de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :title="shop.name"
                        style="height:400px;display: block;margin-left: auto;margin-right: auto; width: 100%;" />
                    <div class="container titlecontainershop" style="margin-top: 20px;">
                        <h4><b>{{ shop.name }}</b></h4>
                        <br>
                        <h5 v-html="shop.resumer"></h5>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br><br>
        <br>
        <br>
        <br>

    </div>

</template>

<style src="../assets/css/shops.css"></style>
<script>
export default {
    data() {
        return {
            shops: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getBoutiques/${idSite}`)
            .then(response => response.json())
            .then(data => {
                this.shops = data;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
